
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





































































































.smartphones [class*='smartphones--'] {
}

.smartphones__devices__keypoints {
  margin: 0;
  padding: 0;
}

.smartphones__devices__title {
  margin: 0 0 $spacing;

  @include mq(m) {
    margin: 0 0 $spacing * 1.5;
  }

  @include mq(xl) {
    margin: 0 0 $spacing * 2.5;
  }
}

.smartphones__devices__keypoints__item {
  display: flex;
  align-items: flex-start;
  list-style-type: none;

  .icon {
    flex-shrink: 0;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: $spacing * 0.5;
    fill: $c-pink-medium;
  }

  &:not(:last-child) {
    margin-bottom: $spacing;
  }

  @include mq(m) {
    width: col(6);

    .icon {
      margin-right: $spacing;
    }
  }

  @include mq(xl) {
    width: col(4);
  }
}

.smartphones__devices__keypoints__item__text {
  color: $c-blue-dark;
  font-size: 1.6;
  line-height: 1.6;

  @include mq(m) {
    font-size: 1.8;
  }
}
